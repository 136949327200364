<script lang="tsx">
import { defineComponentBaseUiButton } from '@core/app/components/base/ui/BaseUiButton.vue'
import type { ButtonVariants } from '@core/types/components'

export type ButtonColors = 'basic' | 'primary' | 'success' | 'warning' | 'danger'
type Sizes = 'small' | 'medium' | 'large' | 'none'
type Variants = ButtonVariants | 'plain'

export default defineComponentBaseUiButton<ButtonColors, Variants, Sizes>({
    props: {
        size: {
            default: 'medium',
        },
        variant: {
            default: 'solid',
        },
        color: {
            default: 'basic',
        },
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiButton.scss" as *;

@include button {
    border-radius: $mon-border-radius;
    text-decoration: none;

    transition: background-color $mon-trans-time-normal $mon-timing, color $mon-trans-time-normal $mon-timing;

    @include mon-text-base;
}

@include button--disabled {
    background-color: $mon-c-basic-200;
    color: $mon-c-white;
}

@include button--variant(outlined) {
    @include button--disabled('&') {
        opacity: 0.5;
    }
}

@include button--variant(plain) {
    @include button--disabled('&') {
        background-color: transparent;
        opacity: 0.5;
    }
}

@include button--variant(bare) {
    border-radius: 0;

    @include button--disabled('&') {
        background-color: transparent;
        opacity: 0.5;
    }
}

@include button--variant(outlined) {
    border: 1px solid var(--outline-color);
}

@include button--variant(outlined) {
    @include content {
        margin: -1px;
    }
}

// --------------------------------------------------------------------
// Button sizes

@include button--size(small) {
    padding: 0.25rem 1rem;

    @include mon-text-small;

    @include button--square('&') {
        padding: 0.25rem;
    }
}

@include button--size(medium) {
    padding: 0.625rem 1.5625rem;

    @include mon-text-base;

    @include button--square('&') {
        padding: 0.625rem;
    }
}

@include button--size(large) {
    padding: 0.875rem 4.25rem;

    @include mon-text-big;

    @include button--square('&') {
        padding: 0.875rem;
    }
}

// --------------------------------------------------------------------
// Button colors

@include button--color('basic') {
    @include button--variant(solid, '&') {
        background-color: $mon-c-white;
        color: $mon-c-black;

        @include button--hover('&') {
            background-color: $mon-c-primary-100;
        }
    }

    @include button--variant(plain, '&') {
        color: $mon-c-black;
    }

    @include button--variant(outlined, '&') {
        color: $mon-c-black;

        --outline-color: #{$mon-c-black};

        @include button--hover('&') {
            background-color: $mon-c-black;
            color: $mon-c-white;
        }
    }
}

@include button--color('primary') {
    @include button--variant(solid, '&') {
        background-color: $mon-c-primary-500;
        color: $mon-c-white;

        @include button--hover('&') {
            background-color: #413573;
        }
    }

    @include button--variant(outlined, '&') {
        background-color: $mon-c-white;
        color: $mon-c-primary-500;

        --outline-color: #{$mon-c-primary-500};

        @include button--hover('&') {
            background-color: $mon-c-primary-500;
            color: $mon-c-white;
        }
    }

    @include button--variant(plain, '&') {
        color: $mon-c-primary-500;
    }

    @include button--disabled('&') {
        background-color: $mon-c-primary-300;
        color: $mon-c-white;
    }
}

@include button--color('success') {
    @include button--variant(solid, '&') {
        background-color: $mon-c-success-500;
        color: $mon-c-white;

        @include button--hover('&') {
            background-color: rgba($mon-c-success-500, 0.8);
        }
    }

    @include button--variant(outlined, '&') {
        background-color: $mon-c-white;
        color: $mon-c-success-500;

        --outline-color: #{$mon-c-success-500};

        @include button--hover('&') {
            background-color: $mon-c-success-500;
            color: $mon-c-white;
        }
    }

    @include button--variant(plain, '&') {
        color: $mon-c-success-500;
    }
}

@include button--color('warning') {
    @include button--variant(solid, '&') {
        background-color: $mon-c-warning-500;
        color: $mon-c-white;

        @include button--hover('&') {
            background-color: rgba($mon-c-warning-500, 0.8);
        }
    }

    @include button--variant(outlined, '&') {
        background-color: $mon-c-white;
        color: $mon-c-warning-500;

        --outline-color: #{$mon-c-warning-500};

        @include button--hover('&') {
            background-color: $mon-c-warning-500;
            color: $mon-c-white;
        }
    }

    @include button--variant(plain, '&') {
        color: $mon-c-warning-500;
    }
}

@include button--color('danger') {
    @include button--variant(solid, '&') {
        background-color: $mon-c-danger-500;
        color: $mon-c-white;

        @include button--hover('&') {
            background-color: rgba($mon-c-danger-500, 0.8);
        }
    }

    @include button--variant(outlined, '&') {
        background-color: $mon-c-white;
        color: $mon-c-danger-500;

        --outline-color: #{$mon-c-danger-500};

        @include button--hover('&') {
            background-color: $mon-c-danger-500;
            color: $mon-c-white;
        }
    }

    @include button--variant(plain, '&') {
        color: $mon-c-danger-500;
    }
}

</style>
