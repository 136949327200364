<script lang="tsx">
import { defineComponentBaseContainerContent } from '@core/app/components/base/container/BaseContainerContent.vue'

export type PaddingSizes = 'small' | 'normal' | 'large' | 'extra-large'
type ContentWidths = 'narrow' | 'normal'

export default defineComponentBaseContainerContent<PaddingSizes, ContentWidths>({
    props: {
        padding: {
            default: 'normal',
        },
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseContainerContent" as *;

// Content widths
@include define-max-width('normal', 1920px);
@include define-max-width('narrow', 1440px);

// Small padding size
@include define-padding-size('small', 1.875rem, 0.625rem, 1.875rem, 0.625rem);

// Default padding size
@include define-padding-size('normal', 1.875rem, 0.625rem, 1.875rem, 0.625rem);
@include define-padding-size-after-breakpoint('normal', 'md', 3.125rem, 1.625rem, 3.125rem, 1.625rem);
@include define-padding-size-after-breakpoint('normal', 'xl', 3.75rem, 5rem, 3.75rem, 5rem);

// Large padding size
@include define-padding-size('large', 3.125rem, 1rem, 3.125rem, 1rem);
@include define-padding-size-after-breakpoint('large', 'md', 6.25rem, 4.25rem, 6.25rem, 4.25rem);
@include define-padding-size-after-breakpoint('large', 'xxl', 6.25rem, 7.5rem, 6.25rem, 7.5rem);

// Extra large padding size
@include define-padding-size('extra-large', 3.75rem, 1rem, 3.75rem, 1rem);
@include define-padding-size-after-breakpoint('extra-large', 'md', 6.25rem, 4.25rem, 6.25rem, 4.25rem);
@include define-padding-size-after-breakpoint('extra-large', 'xxl', 7.5rem, 7.5rem, 7.5rem, 7.5rem);


</style>
